import React, {useContext, useState} from 'react';
import { Box, AppBar, Toolbar, IconButton, Menu, MenuItem, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useSignOut } from 'react-firebase-hooks/auth';
import firebaseApp from '../firebase';
import logo from '../lodi-transparent.png';
import Image from 'mui-image';
import {AuthContext} from "../contexts/AuthContext";


export default function LodiAppBar() {
    const user = useContext(AuthContext);
    const [signOut] = useSignOut(firebaseApp.auth);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    let paymentLink = 'https://donate.stripe.com/fZe8zQ8m15dUcjCaEE';
    if (process.env.NODE_ENV !== 'production') {
        paymentLink = 'https://donate.stripe.com/test_3cs2b9biweRb2is4gg';
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={handleMenuClick}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem component={Link} to="/" onClick={handleClose}>Home</MenuItem>
                        <MenuItem component={Link} to="/collection" onClick={handleClose}>Collection</MenuItem>
                        <MenuItem component={Link} to="/connect-discogs" onClick={handleClose}>Connect Discogs</MenuItem>
                        <MenuItem component={Link} target="_blank" to={paymentLink} onClick={handleClose}>Support The Team</MenuItem>
                    </Menu>
                    <Image
                        src={logo}
                        height={50}
                        width={100}
                        duration={0}
                    />
                    <Box>
                        {user && <Button color="inherit" onClick={signOut}>Logout</Button>}
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
