/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import LibraryMusicOutlinedIcon from '@mui/icons-material/LibraryMusicOutlined';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import AssistantIcon from '@mui/icons-material/Assistant';
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import {
    BottomNavigation,
    BottomNavigationAction,
    Paper,
} from "@mui/material";
import {Link, useLocation} from "react-router-dom";

export default function LodiNavFooter() {
    const routes = ['/home', '/collection', '/connect-discogs', '/daily-recommendation']

    const { pathname } = useLocation();
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        setValue(routes.indexOf(pathname))
    }, [pathname]);

    return (
        <Paper
            sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
            elevation={3}
        >
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                <BottomNavigationAction label="Home"            to="/home"            component={Link} icon={value === 0 ? <HomeIcon color={'secondary'}/> : <HomeOutlinedIcon color={'primary'}/>} />
                <BottomNavigationAction label="Collection"      to="/collection"                component={Link} icon={value === 1 ? <LibraryMusicIcon color={'secondary'}/> : <LibraryMusicOutlinedIcon color={'primary'}/>} />
                <BottomNavigationAction label="Connect" to="/connect-discogs" component={Link} icon={value === 2 ? <CloudSyncIcon color={'secondary'}/> : <CloudSyncOutlinedIcon />} color={'primary'}/>
                <BottomNavigationAction label="Suggested" to="/daily-recommendation" component={Link} icon={value === 3 ? <AssistantIcon color={'secondary'}/> : <AssistantOutlinedIcon />} color={'primary'}/>
            </BottomNavigation>
        </Paper>
    );
}