import React, {useState} from "react";
import {
    Box,
    CircularProgress, IconButton,
    Typography
} from "@mui/material";
import { Link } from 'react-router-dom';
import {UserCollectionItem} from "../pages/CollectionPage";
import Image from "mui-image";
import tidalLogo from "../tidal-round-black-icon.webp";
import ErrorIcon from "@mui/icons-material/Error";
import theme from "../theme";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import firebaseApp from "../firebase";

export interface CollectionItemDisplayCardProps {
    collectionItem: UserCollectionItem,
    onRefreshItem: any,
}

export default function CollectionItemDisplayCard(props: CollectionItemDisplayCardProps) {
    const [isLoadingItemLink, setIsLoadingItemLInk] = useState<boolean>(false);
    console.dir(props.collectionItem)
    const isVideoCover = props.collectionItem.imageUrl.includes(".mp4");

    async function getCollectionItemLink() {
        let tidalId = props.collectionItem.tidalId;

        if (!tidalId) {
            const result = await firebaseApp.functions.addTidalIdToCollectionItem({discogsId: props.collectionItem.discogsId});

            if (!result || !result.data) {
                return null;
            }

            // @ts-ignore
            tidalId = result.data.tidalId;
            // @ts-ignore
            props.onRefreshItem(tidalId, result.data.tidalImageUrl)
        }

        return `https://tidal.com/browse/album/${tidalId}`;
    }

    function renderImageOverlays() {
        return (
            <>
                {props.collectionItem.tidalId && <Image
                    position={'relative'}
                    wrapperStyle={{top: '-18%', left: '85%', position: 'relative'}}
                    height={'24px'}
                    width={'24px'}
                    src={tidalLogo}
                />}
                {!props.collectionItem.tidalId && props.collectionItem.tidalSearchFailure &&
                    <ErrorIcon
                        style={{top: '-15%', left: '85%', position: 'relative'}}
                        color={'error'}
                    />
                }
                {(props.collectionItem.tidalId || props.collectionItem.tidalSearchFailure) &&
                    <Box
                        sx={{
                            width: 'fit-content',
                            borderRadius: '50%',
                            backgroundColor: theme.palette.primary.main,
                            position: 'relative',
                            top: '-110%',
                            left: '80%',
                            opacity: .8
                        }}
                    >
                        <IconButton color={'secondary'} size={'small'}
                                    component={Link}
                                    to={`/edit-item/${props.collectionItem.discogsId}`}>
                            <EditOutlinedIcon />
                        </IconButton>
                    </Box>
                }
            </>
        )
    }

    function handleMediaClick() {
        if (isLoadingItemLink) {
            return;
        }

        setIsLoadingItemLInk(true);
        getCollectionItemLink().then(link => {
            setIsLoadingItemLInk(false)
            if (!link) {
                return;
            }
            window.open(link, "_blank")
        }).catch(ex => {
            return
        })
    }

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
        >
            { isLoadingItemLink ? <CircularProgress size={'200px'}/> : isVideoCover ?
                <Box
                    height={'200px'}
                    width={'200px'}
                >
                    <Box
                        autoPlay={true}
                        loop={true}
                        muted={true}
                        component={'video'}
                        height={'200px'}
                        width={'200px'}
                        onClick={() => handleMediaClick()}
                    >
                        <source src={props.collectionItem.imageUrl} type={"video/mp4"}/>
                    </Box>
                    {renderImageOverlays()}
                </Box>
                :
                <Box
                    height={'200px'}
                    width={'200px'}
                >
                    <Box
                        onClick={() => {
                            handleMediaClick()
                        }}
                        height={'200px'}
                        width={'200px'}
                    >
                        <Image
                            height={'200px'}
                            width={'200px'}
                            src={props.collectionItem.imageUrl}
                        />
                    </Box>
                    {renderImageOverlays()}
                </Box>
            }
            <Box maxWidth={'200px'}>
                <Typography variant={'subtitle1'}>{props.collectionItem.title}</Typography>
            </Box>
            <Box maxWidth={'200px'}>
                <Typography variant={'subtitle2'}>{props.collectionItem.artist}</Typography>
            </Box>
        </Box>
    );
}