import {
    useCreateUserWithEmailAndPassword,
    useSignInWithEmailAndPassword,
    useSignInWithGoogle, useUpdateProfile
} from "react-firebase-hooks/auth";
import firebaseApp from "../firebase"
import {Alert, Box, Button, CircularProgress, Divider, Paper, TextField, Typography} from "@mui/material";
import React, { useState } from "react";

export default function SignInCard() {
    const [ isRegisterEmailSelected, setIsRegisterEmailSelected ] = useState(false);
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');

    const [ updateProfile,, updateUserLoading ] = useUpdateProfile(firebaseApp.auth)
    const [ createUserWithEmailAndPassword,, emailSignUpLoading, emailSignUpError ] = useCreateUserWithEmailAndPassword(firebaseApp.auth);
    const [ signInWithEmailAndPassword,, emailSignInLoading, emailSignInError ] = useSignInWithEmailAndPassword(firebaseApp.auth);
    const [ signInWithGoogle,, googleSignInLoading, googleSignInError ] = useSignInWithGoogle(firebaseApp.auth);

    function calcIsLoading() {
        return updateUserLoading || googleSignInLoading || emailSignInLoading || emailSignUpLoading;
    }

    function calcErrorMessage() {
        if (emailSignUpError) {
            return emailSignUpError;
        }


        if (emailSignInError) {
            return emailSignInError
        }

        if (googleSignInError) {
            return googleSignInError
        }
    }

    function renderEmailRegister() {
        return <>
            <TextField
                variant={"outlined"}
                type={"text"}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={"name"}
                id={"name-input"}
                autoComplete={"name"}
                required
            />
            <TextField
                variant={"outlined"}
                type={"text"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={"email"}
                id={"username-input"}
                autoComplete={"username"}
                required
            />
            <TextField
                variant={"outlined"}
                type={"password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={"password"}
                id={"password-input"}
                autoComplete={"new-password"}
                required
            />
            <Button
                variant={"contained"}
                onClick={() => {
                    createUserWithEmailAndPassword(email, password).then(() => {
                        updateProfile({displayName: name})
                    })
            }}>
                Register
            </Button>
            <Button onClick={() => setIsRegisterEmailSelected(false)}>Already have an account?</Button>
        </>
    }

    function renderEmailSignIn() {
        return <>
            <TextField
                variant={"outlined"}
                type={"text"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={"email"}
                id={"username-input"}
                autoComplete={"username"}
                required
            />
            <TextField
                variant={"outlined"}
                type={"password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={"password"}
                id={"password-input"}
                autoComplete={"current-password"}
                required
            />
            <Button variant={"contained"} onClick={() => signInWithEmailAndPassword(email, password)}>
                Sign In
            </Button>
            <Button onClick={() => setIsRegisterEmailSelected(true)}>Don't have an account?</Button>
        </>
    }

    function renderGoogleSignIn() {
        return <Button variant={"contained"} onClick={() => signInWithGoogle()}>continue with Google</Button>;
    }

    function renderSignInSection() {
        return <Box sx={{display: "flex", flexDirection: "column", gap: "4px"}}>
            {isRegisterEmailSelected ? renderEmailRegister() : renderEmailSignIn()}
            <Divider variant={"middle"} flexItem aria-hidden="true">or</Divider>
            {renderGoogleSignIn()}
        </Box>
    }

    function renderHeadingSection() {
        return <Box padding={3} sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Typography variant={'h3'}>Welcome to lodi</Typography>
            <Typography variant={'h6'}>for hi quality</Typography>
        </Box>
    }

    function renderErrorMessage() {
        const errorMsg = calcErrorMessage();

        if (!errorMsg) {
            return;
        }

        return <Alert severity={"error"}>{errorMsg.message}</Alert>
    }

    return <Paper
        sx={{
            display: "flex",
            border: "1px solid",
            maxWidth: "fit-content",
            borderRadius: "4px",
            flexDirection: "column",
            margin: "16px",
            padding: "8px",
            gap: "8px",
            alignItems: "center"
    }}>
        {renderHeadingSection()}
        {renderErrorMessage()}
        {calcIsLoading() ? <CircularProgress/> : renderSignInSection()}
    </Paper>
}