import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import firebaseApp from "./firebase";
import { connectFunctionsEmulator } from 'firebase/functions';
import { connectAuthEmulator } from 'firebase/auth';
import {AuthProvider} from "./contexts/providers/AuthProvider";
import { connectFirestoreEmulator } from 'firebase/firestore';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

if (process.env.NODE_ENV !== 'production') {
    console.log('Connecting to emulators')
    connectFunctionsEmulator(firebaseApp.cloudFunctions, '127.0.0.1', 5001)
    connectAuthEmulator(firebaseApp.auth, 'http://127.0.0.1:9099')
    connectFirestoreEmulator(firebaseApp.firestore, 'localhost', 8080)
}

root.render(
    <ThemeProvider theme={theme}>
        <AuthProvider>
            <CssBaseline />
            <App />
        </AuthProvider>
    </ThemeProvider>,
);

serviceWorkerRegistration.register();

reportWebVitals();
