import Container from "@mui/material/Container";
import * as React from "react";
import {CircularProgress, Stack, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import firebaseApp from "../firebase";
import {AuthContext} from "../contexts/AuthContext";
import {UserCollectionItem} from "./CollectionPage";
import {useNavigate} from "react-router-dom";
import {collection, getDocs, limit, query, where} from "firebase/firestore";
import RecommendationItemCard from "../components/RecommendationItemCard";

const DailyRecommendationPage = () => {
    const user = useContext(AuthContext);
    const [collectionItem, setCollectionItem] = useState<UserCollectionItem | null>();
    const [recommendedTidalItems, setRecommendedTidalItems] = useState<UserCollectionItem[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const db = firebaseApp.firestore;
        user?.getIdTokenResult().then((userToken) => {
            if (!userToken.claims.discogsUsername) {
                navigate("/connect-discogs")
                return
            }

            getDocs(query(collection(db, "userCollections", `${userToken.claims.discogsUsername}`, "releases"), where("tidalId", "!=", null), limit(100))).then((querySnapshot) => {
                const randomIdx = Math.floor(Math.random() * Math.min(querySnapshot.docs.length, 100));
                const collectionItem: UserCollectionItem = querySnapshot.docs[randomIdx]?.data() as UserCollectionItem;
                setCollectionItem(collectionItem);
                if (!collectionItem) {
                    navigate("/collection")
                }

                firebaseApp.functions.getRecommendedAlbums({tidalId: collectionItem.tidalId}).then((recommendedAlbums: any) => {
                    const recommendedAlbumResources = recommendedAlbums.data.data.map((album: any) => {
                        return {
                            artist: album.resource.artists[0].name,
                            discogsId: "",
                            discogsMasterReleaseId: 0,
                            imageUrl: album.resource.imageCover[0].url,
                            releaseYear: album.resource.releaseDate,
                            tidalId: album.resource.id,
                            tidalSearchFailure: false,
                            title: album.resource.title,
                        } as UserCollectionItem
                    });

                    setRecommendedTidalItems(recommendedAlbumResources)
                });
            });
        })
    }, [navigate, user]);

    function renderRecommendations() {
        const recommendations = []

        for (const recommendedTidalItem of recommendedTidalItems) {
            recommendations.push(<RecommendationItemCard recommendedTidalItem={recommendedTidalItem} key={recommendedTidalItem.tidalId}/>);
        }

        return recommendations
    }

    return (
        <Container maxWidth="sm">
            <Stack>
                {recommendedTidalItems ?
                    <>
                        <Typography variant={'h6'} align={'center'}>
                            Recommended because of: {collectionItem?.title} by {collectionItem?.artist}
                        </Typography>
                        {renderRecommendations()}
                    </>
                    : <CircularProgress/>
                }
            </Stack>
        </Container>
    )
}

export default DailyRecommendationPage;