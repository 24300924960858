/* eslint-disable react-hooks/exhaustive-deps */
import Container from "@mui/material/Container";
import * as React from "react";
import {CircularProgress, Divider, Stack} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import firebaseApp from "../firebase";
import {collection, getDocs, limit, query, where} from "firebase/firestore";
import {AuthContext} from "../contexts/AuthContext";
import {UserCollectionItem} from "./CollectionPage";
import {useNavigate} from "react-router-dom";

const HomePage = () => {
    const user = useContext(AuthContext);
    const [userCollectionItem, setUserCollectionItem] = useState<UserCollectionItem | null>();
    const navigate = useNavigate();

    useEffect(() => {
        const db = firebaseApp.firestore;
        user?.getIdTokenResult().then((userToken) => {
            if (!userToken.claims.discogsUsername) {
                navigate("/connect-discogs")
                return
            }
            getDocs(query(collection(db, "userCollections", `${userToken.claims.discogsUsername}`, "releases"), where("tidalId", "!=", null), limit(25))).then((querySnapshot) => {
                const randomIdx = Math.floor(Math.random() * Math.min(querySnapshot.docs.length, 25));
                const collectionItem: UserCollectionItem = querySnapshot.docs[randomIdx]?.data() as UserCollectionItem;
                if (!collectionItem) {
                    navigate("/collection")
                }
                setUserCollectionItem(collectionItem);
            });
        })
    }, []);

    function renderComingSoon() {
        return (
            <Container maxWidth="sm">
                {userCollectionItem ? <div className="tidal-embed"
                      style={{
                          position: "relative",
                          paddingBottom: "100%",
                          height: 0,
                          overflow: "hidden",
                          "maxWidth": "100%"
                      }}>
                    <iframe
                        src={`https://embed.tidal.com/albums/${userCollectionItem?.tidalId}?layout=gridify&coverInitially=true`}
                        allowFullScreen frameBorder="0"
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "1px",
                            minHeight: "100%",
                            margin: "0 auto"
                        }}
                        title={'Music Preview'}
                    ></iframe>
                </div> : <CircularProgress/>}
            </Container>
        )
    }

    return (
        <Container maxWidth="sm">
            <Stack alignItems={"center"} sx={{ my: 4 }}>
                { renderComingSoon() }
                <Divider/>
            </Stack>
        </Container>
    )
}

export default HomePage;