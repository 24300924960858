/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {LinearProgress} from "@mui/material";
import {useEffect} from "react";
import firebaseApp from "../firebase";
import {useNavigate, useSearchParams} from "react-router-dom";

const DiscogsConnectionPage = () => {
    const [searchParams,] = useSearchParams()
    const navigate = useNavigate()

    useEffect(() => {
        const usersVerifier = searchParams.get("oauth_verifier")
        const oauthToken = searchParams.get("oauth_token")
        firebaseApp.functions.getDiscogsAccessToken({verifier: usersVerifier, oauthToken: oauthToken}).then(() => {
            navigate("/collection")
        })
    }, [])

    return (
        <LinearProgress/>
    )
}

export default DiscogsConnectionPage;