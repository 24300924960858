import * as React from 'react';
import LodiAppBar from "./components/LodiAppBar";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from "./pages/HomePage";
import DiscogsConnectionPage from "./pages/DiscogsConnectionPage";
import PrivateRoute from "./PrivateRoute";
import CollectionPage from "./pages/CollectionPage";
import AnonymousRoute from "./AnonymousRoute";
import SignInPage from "./pages/SignInPage";
import DiscogsOAuthPage from "./pages/DiscogsOAuthPage";
import LodiNavFooter from "./components/LodiNavFooter";
import ManualEditCollectionItemPage from "./pages/ManualEditCollectionItemPage";
import DailyRecommendationPage from "./pages/DailyRecommendationPage";


// function Copyright() {
//     return (
//         <Typography variant="body2" color="text.secondary" align="center">
//             {'Copyright © '}
//             lodi-app.web.app
//             {` ${new Date().getFullYear()}`}
//         </Typography>
//     );
// }


export default function App() {

    return (
        <Router>
            <div>
                <LodiAppBar />
                <Routes>
                    <Route path="/home" element={<PrivateRoute><HomePage /></PrivateRoute>} />
                    <Route path="/collection" element={<PrivateRoute><CollectionPage/></PrivateRoute>}/>
                    <Route path="/connect-discogs" element={<PrivateRoute><DiscogsConnectionPage /></PrivateRoute>} />
                    <Route path="/connect-discogs-oauth" element={<PrivateRoute><DiscogsOAuthPage /></PrivateRoute>} />
                    <Route path="/edit-item/:releaseId" element={<PrivateRoute><ManualEditCollectionItemPage /></PrivateRoute>} />
                    <Route path="/daily-recommendation" element={<PrivateRoute><DailyRecommendationPage /></PrivateRoute>} />
                    <Route path="/" element={<AnonymousRoute><SignInPage/></AnonymousRoute>}/>
                </Routes>
                <LodiNavFooter/>
                {/*<Copyright />*/}
            </div>
        </Router>
  );
}