/* eslint-disable react-hooks/exhaustive-deps */
import Container from "@mui/material/Container";
import {CircularProgress, Divider, Grid, Stack} from "@mui/material";
import * as React from "react";
import {JSX} from "react/jsx-runtime";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../contexts/AuthContext";
import firebaseApp from "../firebase";
import { collection, getDocs, query } from "firebase/firestore"
import CollectionItemDisplayCard from "../components/CollectionItemDisplayCard";

export interface UserCollectionItem {
    discogsId: string,
    title: string,
    artist: string,
    imageUrl: string,
    tidalId: string | null,
    releaseYear: string,
    discogsMasterReleaseId: number,
    tidalSearchFailure: boolean,
}

const CollectionPage = () => {
    const user = useContext(AuthContext);
    const [userCollection, setUserCollection] = useState<UserCollectionItem[]>([]);

    useEffect(() => {
        const db = firebaseApp.firestore;
        user?.getIdTokenResult(true).then((userToken) => {
            getDocs(query(collection(db, "userCollections", `${userToken.claims.discogsUsername}`, "releases"))).then((querySnapshot) => {
                const collectionItems: UserCollectionItem[] = [];
                querySnapshot.forEach((doc: any) => {
                    collectionItems.push(doc.data() as UserCollectionItem);
                })
                setUserCollection(collectionItems)
            });
        })
    }, [user]);

    function renderCollectionView() {
        if (!userCollection) {
            return <CircularProgress/>
        }

        const gridItems: JSX.Element[] = []

        userCollection.forEach((item, idx) => {
            // @ts-ignore
            gridItems.push(
                <Grid
                    item
                    xs={12}
                    sm={6}
                    display={'flex'}
                    justifyContent={'center'}
                    id={`${item.title}-${idx}`}
                    key={`${item.title}-${idx}`}
                >
                    <CollectionItemDisplayCard collectionItem={item}
                       onRefreshItem={(tidalId: string, tidalImageUrl: string) => {
                           item.tidalId = tidalId.toString();
                           item.imageUrl = tidalImageUrl;
                        }}
                    />
                </Grid>
            )
        });

        return (
            <Grid container spacing={4}>
                {gridItems}
            </Grid>
        )
    }

    return (
        <Container maxWidth="sm">
            <Stack alignItems={"center"} sx={{ my: 4 }}>
                {renderCollectionView()}
                <Divider/>
            </Stack>
        </Container>
    )
}

export default CollectionPage;