import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore';


// This info is safe to publish
const firebaseConfig = {
    apiKey: "AIzaSyBnOcuvC0tCztlPa-UuIZVMWHc9nlpVmQ4",
    authDomain: "lodi-app.firebaseapp.com",
    projectId: "lodi-app",
    storageBucket: "lodi-app.appspot.com",
    messagingSenderId: "810357664329",
    appId: "1:810357664329:web:e13be6bc24fc250c22ed98",
    measurementId: "G-1V5SQE7ZTV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const cloudFunctions = getFunctions(app)
const auth = getAuth(app)
const defaultStorage = getStorage(app)
const firestore = getFirestore(app);

const connectDiscogsPersonalAccessToken = httpsCallable(cloudFunctions, 'connectDiscogsPersonalAccessToken');
const addTidalIdToCollectionItem = httpsCallable(cloudFunctions, 'addTidalIdToCollectionItem');
const beginDiscogsOAuthFlow = httpsCallable(cloudFunctions, 'beginDiscogsOAuthFlow');
const getDiscogsAccessToken = httpsCallable(cloudFunctions, 'getDiscogsAccessToken');
const overwriteTidalData = httpsCallable(cloudFunctions, 'overwriteTidalData');
const getRecommendedAlbums = httpsCallable(cloudFunctions, 'getRecommendedAlbums');

const functions = {
    connectDiscogsPersonalAccessToken,
    addTidalIdToCollectionItem,
    beginDiscogsOAuthFlow,
    getDiscogsAccessToken,
    overwriteTidalData,
    getRecommendedAlbums,
}

const firebaseApp = {
    app,
    analytics,
    auth,
    cloudFunctions,
    defaultStorage,
    functions,
    firestore
}

export default firebaseApp;