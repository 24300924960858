import {Button, Paper} from "@mui/material";
import * as React from "react";

interface RecommendationItemCardProps {
    recommendedTidalItem: any
}

export default function RecommendationItemCard(props: RecommendationItemCardProps) {
    return (
        <Paper sx={{display: 'flex', flexDirection: 'column', padding: 1, margin: 1}}>
            <div className="tidal-embed"
                 style={{
                     position: "relative",
                     paddingBottom: "100%",
                     height: 0,
                     overflow: "hidden",
                     "maxWidth": "100%",
                     marginBottom: 1,
                 }}>
                <iframe
                    src={`https://embed.tidal.com/albums/${props.recommendedTidalItem?.tidalId}?layout=gridify&coverInitially=true`}
                    allowFullScreen frameBorder="0"
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "1px",
                        minHeight: "100%",
                        margin: "0 auto"
                    }}
                    title={'Music Preview'}
                ></iframe>
            </div>
            <Button variant={'contained'} onClick={() => {
                const link = `https://www.discogs.com/search?q=${props.recommendedTidalItem?.title}+${props.recommendedTidalItem?.artist}&type=release&release_title=${props.recommendedTidalItem?.title}&artist=${props.recommendedTidalItem?.artist}`;
                window.open(link, "_blank")
            }}>
                Search on discogs
            </Button>
        </Paper>
    )
}