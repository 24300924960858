import Container from "@mui/material/Container";
import * as React from "react";
import {Button, Stack} from "@mui/material";
import {useContext, useState} from "react";
import firebaseApp from "../firebase";
import {AuthContext} from "../contexts/AuthContext";

const DiscogsConnectionPage = () => {
    const user = useContext(AuthContext);
    const [ discogsUserName, ] = useState('');
    const [ discogsAccessToken, ] = useState('');

    const beginDiscogsOAuthFlow = async (discogsAccessToken: string) => {
        const redirectUrl: any = await firebaseApp.functions.beginDiscogsOAuthFlow({discogsUserName: discogsUserName, discogsPat: discogsAccessToken})
        user?.getIdTokenResult(true)
        console.log(redirectUrl)

        window.location = redirectUrl?.data;
    }

    return (
        <Container maxWidth="sm">
            <Stack>
                <Button variant={"contained"} onClick={() => beginDiscogsOAuthFlow(discogsAccessToken)}>
                    Connect Account (OAuth)
                </Button>
            </Stack>
        </Container>
    )
}

export default DiscogsConnectionPage;