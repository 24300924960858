import {Button, Stack, TextField} from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";
import {useState} from "react";
import firebaseApp from "../firebase";
import {useNavigate, useParams} from "react-router-dom";


const ManualEditCollectionItemPage  = () => {
    const navigate = useNavigate()
    const { releaseId } = useParams();
    const [tidalUrl, setTidalUrl] = useState<string | undefined>();

    function handleSubmitManualEdit() {
        function extractAlbumId(url: string) {
            const urlPieces = url.split("/album/");

            try {
                let potentialId = urlPieces[1].split("/")[0];
                potentialId = potentialId.split("?")[0];
                return potentialId;
            } catch (e) {
                return null;
            }
        }

        if (!tidalUrl) {
            setTidalUrl("Error")
            return;
        }

        const tidalId = extractAlbumId(tidalUrl)
        if (!tidalId) {
            setTidalUrl("Error 2")
            return;
        }

        firebaseApp.functions.overwriteTidalData({tidalId: tidalId, discogsId: releaseId}).then(() => {
            navigate("/collection");
        })
    }

    function renderManualEditForm() {
        return <>
            <TextField
                variant={"outlined"}
                type={"text"}
                value={tidalUrl}
                onChange={(e) => setTidalUrl(e.target.value)}
                placeholder={"tidal album url"}
                id={"tidal-url-input"}
                required
            />
            <Button variant={'contained'} onClick={() => handleSubmitManualEdit()}>Set New Tidal Link</Button>
        </>
    }

    return (
        <Container maxWidth="sm">
            <Stack alignItems={"center"} sx={{ my: 4 }}>
                {renderManualEditForm()}
            </Stack>
        </Container>
    )
}

export default ManualEditCollectionItemPage;